@import 'index';

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  //font-family: 'Raleway', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

input,
textarea {
  background-color: #fff;
  border: 0;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  font-size: px2rem(16);
  font-weight: 400;
  line-height: px2rem(20);
  margin: 0;
  max-width: 100%;
  padding: 8px;
  -webkit-transition: box-shadow 0.15s;
  transition: box-shadow 0.15s;
  vertical-align: middle;
  -webkit-appearance: none;
}

select {
  outline: none;
  background: $white;
  padding: px2rem(8) px2rem(16);
  border-radius: px2rem(4);
  text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  cursor: default;
}

.container {
  @include for-tablet-portrait-down {
    max-width: 100%;
  }
}

.page {
  padding-bottom: 14.75rem;
  @media (max-width: 321px) {
    padding-bottom: 34.20rem!important;
  }
  @media (max-width: 400px) {
    padding-bottom: 24rem!important;
  }
  @include for-tablet-landscape-down {
    padding-bottom: 22.60rem;
  }
}
