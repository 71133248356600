@import "../../../style/index";

.wrapper {
  @extend .light-bg;
  position: relative;
  cursor: pointer;
  border: none;
  width: px2rem(50);
  height: px2rem(25);
  border-radius: px2rem(20);
  transition: all 0.3s;
  &.active {
    @extend .blue-bg
  }
  &:focus, &.active:hover {
    outline: none;
  }
}

.indicator {
  @extend .white-bg;
  cursor: pointer;
  position: absolute;
  left: px2rem(2.5);
  top: px2rem(1.5);
  width: px2rem(20);
  height: px2rem(20);
  border-radius: px2rem(10);
  @include boxShadow(10px);
  transition: all 0.3s;
  &.active {
    left: px2rem(25);
  }
}