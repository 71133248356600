@import "../../../../style/index";

#sort-by-component {
  display: flex;
  .dropdown {
    margin-left: 1px;
    button:hover, button:focus {
      @extend .btn-filter:hover;
    }
  }

  .btn-filter {
    margin: 0 1px;
    @extend .btn;
    @extend .btn-bordered;
    @extend .btn-bordered-blue;
  }

  .active, .btn-filter:hover {
    background-color: $blue!important;
    color: $white!important;
    border-color: $blue!important;
  }

  .btn-filter {
    @include for-tablet-portrait-down {
      display: none;
    }
  }
}