/**
 * Colors
 */
$blue: #3e9acf;
$dark-blue: #112938;
$green: #4f9b2c;
$dark-green: #1c3e11;
$yellow: #facd33;
$dark-yellow: #d29f02;
$red: #A00;
$dark-red: #6f0101;
$grey: #484848;
$dark-grey: #1d1d1d;
$black: #000000;
$light: #ccd1d4;
$white: #ffffff;

/**
 * Sizes
 **/
$sm-width: 576px;
$md-width: 768px;
$lg-width: 992px;
$xl-width: 1200px;
$xxl-width: 2400px;