@import "../../style/index";

#about-us-page {
  vertical-align: middle;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  .container {
    .header {
      font-family: 'Raleway', sans-serif;
      margin-top: 1.5rem;
    }
    .icon {
      color: $blue;
      font-size: 50px;
    }
    p {
      font-size: 14px;
      text-align: justify;
      b {
        font-size: 15px;
      }
    }
  }
  .contact-container {
    background-position: center;
    background-size: cover;
    width: 100%;
    min-height: 250px;
    display: flex;
    align-items: stretch;
    .contact-button {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      .icon-btn {
        font-size: 1.5rem;
      }
    }
  }
}