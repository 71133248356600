@import "../../style/index";


#contact-page {
  vertical-align: middle;
  text-align: center;
  .contact-container {
    background-position: bottom;
    background-size: cover;
    min-height: 600px;
    .form-container {
      top: 5em;
      left: 10%;
      padding: 40px 40px 40px 40px;
      background-color: #ffffffde;
      position: relative;
      width: 80%;
      border-radius: 5px;
      @include for-tablet-landscape-down {
        top: 0;
        left: 5%;
        width: 90%;
        margin: 0;
      }
      h3 {
        font-weight: bold;
      }
      .phone-container{
        font-size: 18px;
      }
      .separation {
        border-left: 1px solid $grey;
        @media (max-width: $md-width) {
          border-left: none;
          border-bottom: 1px solid $grey;
          margin-bottom: 15px;
        }
      }
    }
  }
}