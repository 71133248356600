@import "../../../style/index";

#result-sale {
  .found-elements {
    text-align: center;
  }

  #header-sale {
    @include for-desktop-down {
      display: none;
    }
  }
}