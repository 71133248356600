@import "../../style/index";

#home-page {
  vertical-align: middle;
  text-align: center;
  #contact-container {
    background-position: top;
    background-size: cover;
    width: 100%;
    height: auto;
    min-height: 510px;
    @media (max-width: $sm-width) {
      min-height: 501px;
    }
    #form-container {
      float: right;
      top: 3em;
      padding: 15px 40px 40px 40px;
      background-color: #fffffff2;
      position: relative;
      min-width: 50%;
      border-radius: 5px;
      @include for-tablet-landscape-down {
        top: 10px;
      }
      @include for-ultrawide-up {
        max-width: 40%;
      }
      h3 {
        margin-bottom: 1.5rem;
      }
    }
  }
}