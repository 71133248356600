@import "../../../style/index";

#modal-images-property {
  max-width: 100vw;
  min-height: 100vh;
  margin: 0;
  overflow: hidden;

  .modal-content {
    background-color: #000000d1;
    color: $light;
    min-height: 100vh;
  }
  .modal-header {
    border: none;
  }


  button > svg {
    font-size: 50px;
  }
  button > span {
    color: $light;
    font-size: 70px;
    font-weight: 100;
  }

  .modal-container {
    display: flex;
    align-items: flex-start;

    @include for-tablet-portrait-down {
      position: relative;
      align-items: center;
      height: 70vh;
    }

    .right-position {
      @include for-tablet-portrait-down {
        right: 0;
      }
    }

    .arrows {
      display: flex;
      align-items: center;
      height: 60vh;
      .btn {
        height: 100%;
      }
      @include for-tablet-portrait-down {
        position: absolute;
      }
    }

    .image-block {
      flex-grow: 2;
      margin: 0 auto;
      display: flex;
      max-height: 70vh;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 60vh;
        border-radius: 3px;
        border: 1px solid $grey;
      }
    }
  }
}