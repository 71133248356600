@import "../../../style/index";

#property-carousel {
  .btn-arrows {
    font-size: 2em;
    padding-right: 0;
    padding-left: 0;
  }
  .arrow-buttons {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .react-multi-carousel-track {
    margin-top: 65px;
    li {
      padding: 0 15px;
    }
  }
}