@import "../../../style/index";

#modal-by-ref {
  .modal-content {
    margin-top: 60px;
  }
  .modal-header, .modal-footer {
    border: none;
  }
  .not-found {
    background-color: $red;
    color: $white;
    border: none;
    padding: 10px;
    border-radius: 5px;
  }
}