@import "../../style/index";

#property-page {
   .sticky-form {
     position: sticky;
     top: 60px;
     text-align: center;
     padding: 15px;
     border-radius: 3px;
     font-size: 20px;
     -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
     -moz-box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
     box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
     .contact-form {
       .form-row > .col, .form-row > [class*=col-] {
         flex: 0 0 100%;
         max-width: 100%;
       }
       .btn {
         width: 100%;
       }
     }
   }
}