@import "../../../../style/index";

#price-label {
  width: 100%;
  /*Input number remove right arrows*/
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .price-row {
    width: 50%;
    @include for-desktop-up {
      width: 100%;
    }
    @include for-tablet-portrait-down {
      width: 80%;
    }
  }
}