@import "../../style/index";

#search-page {
  min-height: 100vh;

  .search-container {
    display: flex;
    flex-direction: column;
    @include for-desktop-up {
      flex-direction: row;
    }
    .btn-filters {
      @include for-desktop-up {
        position: fixed;
        top: 100px;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }
    }

    .without-margin {
      margin-bottom: 0!important;
    }
    .filter-headers {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: px2rem(15);
      #header-sale {
        @include for-desktop-up {
          display: none;
        }
      }
    }

    .criteria, .hide-filters {
      position: sticky;
      border-bottom: 1px solid #484848;
      top: 57px;
      padding: 15px;
      background-color: #fffffff2;
      z-index: 2;
      @include for-desktop-up {
        transition: all 0.6s;
        border-right: 1px solid #484848;
        height: 100vh;
      }
    }
    .criteria {
      min-width: 350px;
      @include  for-desktop-up {
        max-width: 350px;
        border-bottom: none;
      }
      .btn-filters {
        @include for-desktop-up {
          left: 285px;

        }
      }
    }
    .hide-filters {
      min-width: 50px;
      .btn-filters {
        @include for-desktop-up {
          left: -15px;
        }
      }
    }

    .result {
      flex-grow: 1;
      padding: 15px;
    }
  }
}