@import "../../style/index";

#not-found {
  .title {
    text-align: center;
    text-decoration: underline;
    font-size: 5rem;
  }

  .message-container {
    display: flex;
    align-items: center;
    @include for-tablet-landscape-down {
      flex-direction: column-reverse;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    h1 {
      border: 2px solid $blue;
      border-radius: 5px;
      padding: 5px;
      max-width: 300px;
    }
  }
}