@import "../../style/index";

.brand-image {
  width: 125px;
}
.all-menu {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.navbar-color {
  background-color: #ffffffd9;
  border-bottom: 1px solid #e4e4e4;
}

.navbar-collapse {
  @include for-tablet-landscape-down {
    justify-content: center;
  }
}

.navbar-toggler {
  cursor: pointer;
  display: block;
  height: 3.25rem;
  position: relative;
  width: 3.25rem;
  margin-left: auto;
  border: none;

  &:active, &:focus {
    outline: none;
    border: none;
  }
  
  &:hover {
    color: $blue;
  }

  span {
    background-color: currentColor;
    display: block;
    height: 2px;
    left: 50%;
    margin-left: -5px;
    position: absolute;
    top: 50%;
    -webkit-transition: none 86ms ease-out;
    transition: none 86ms ease-out;
    -webkit-transition-property: background,left,opacity,-webkit-transform;
    transition-property: background,left,opacity,transform,-webkit-transform;
    width: 15px;
  }

  span:nth-child(1) {
    margin-top: -6px;
    margin-left: -5px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }
  span:nth-child(2) {
    opacity: 0;
  }
  span:nth-child(3) {
    margin-top: 4px;
    margin-left: -5px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}
.collapsed {  
  span:nth-child(1) {
    margin-top: -6px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }
  span:nth-child(2) {
    opacity: 1;
    margin-top: -1px;
  }
  span:nth-child(3) {
    margin-top: 4px;
    -webkit-transform: rotate(-0);
    transform: rotate(-0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
  }
}

#collapsible-menu {
  a {
    font-weight: bold;
    color: $grey;
    @media (max-width: $sm-width) {
      text-align: center;
    }
    &:hover {
      color: $blue;
    }
  }
  .active {
    color: $blue;
    background: transparent;
  }
}

.language-desktop {
  @include for-tablet-landscape-down {
    display: none;
  }
}

.language-mobile {
  @include for-tablet-landscape-up {
    display: none;
  }
}


