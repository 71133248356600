@import "../../../style/index";
#language-selector {
  max-width: 50px;

  .dropdown {
    display: flex;
    justify-content: right;
    align-content: center;
    flex-grow: 1;

    #btn-language {
      border: none;
      background: none!important;
    }
    .dropdown-toggle::after {
      display: none !important;
    }

    .dropdown-menu {
      left: -100px;
      @include for-tablet-portrait-down {
        right: 0;
      }
    }

    .flag {
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }
  }
}
