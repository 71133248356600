@import "../../style/index";

.footer {
  position: absolute;
  bottom: 0;
  z-index: 99999;
  width: 100%;
  min-height: 12rem;
  background-color: $dark-grey;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .container {
    text-align: left;
    padding: 15px;
    display: flex;
  }
  .flex-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-content: center;
      @include for-tablet-landscape-down {
        flex: auto;
      }
      * {
        align-self: center;
      }
      a {
        margin-bottom: 1rem;
      }
    }
    .description-block {
      flex: 1;
      @include for-tablet-landscape-down {
        flex: auto;
      }
      .title{
        display: flex;
        justify-content: center;
        img {
          margin: 0!important;
        }
        img, h5 {
          display: inline-block;
          margin-bottom: 8px;
        }
      }
    }
  }

  .copyright-footer {
    background-color: $grey;
    padding: 5px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    p {
      margin: 0;
      font-size: 11px;
    }
  }

  .body-text-columns {
    margin-top: 15px;
    text-align: justify;
  }

  .footer-link {
    color: #ffffff;
    font-weight: bold;;
    &:hover {
      color: $blue;
      text-decoration: none;
    }
  }
}