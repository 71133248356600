@import "../../../style/index";

#advanced-criteria {
  display: flex;
  flex-direction: column;
  align-items: center;
  hr {
    width: 100%;
    margin: 0;
  }

  .sold-filter {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    label {
      margin-bottom: 0;
      margin-right: 10px;
    }
    @include for-desktop-down {
      align-self: flex-end;
    }
  }

  .b-filters {
    width: 100%;
    @include for-desktop-down {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    hr {
      @include for-desktop-down {
        display: none;
      }
    }
    .bedroom-filter, .bathroom-filter {
      padding: 15px;
      flex-grow: 1;
      text-align: center;
    }
    .bedroom-filter {
      border-right: 1px solid #e4e4e4;
    }
  }

  .price-filter {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;;
  }
  .col label {
    text-align: center;
    width: 100%;
  }
  .form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h5 {
      padding: 0 15px;
      margin: 0;
      align-self: center;
      min-width: 45px;
    }
  }
}