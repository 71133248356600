@import "../../../../style/index";

#amenities {
    p {
      font-size: 17px;
      svg {
        font-size: 25px;
        margin-right: 15px;
        @extend .blue;
      }
    }
}