@import "../../../style/index";

#header-sale {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  #size {
    margin-left: 15px;
  }
  #sort-by {
    margin-right: 15px;
  }
}