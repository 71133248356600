@import "../../../style/index";

$font-size: 1em;
$line-height: 1.4;
$lines-to-show: 3;

.card {
  cursor: pointer;
  border: none!important;
  margin-bottom: 15px;
  .container-img {
    position: relative;
    text-align: center;
    padding-top: calc((3 / 4) * 100%);
    img {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    label {
      @extend .white;
      position: absolute;
      text-align: center;
      letter-spacing: .06em;
      border-radius: 3px;
      font-size: px2rem(14);
      margin: 0;
    }
      .top-label {
        top: 1em;
        left: -2em;
        padding: 0.3em 2.5em;
        font-weight: bold;
        font-family: Arial, sans-serif;
        text-shadow: 0 0 0.75em #444;
        box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
        -webkit-text-shadow: 0 0 0.75em #444;
        -webkit-box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
        -webkit-transform: rotate(-45deg) scale(0.75, 1);
      }
      .bottom-label {
        bottom: 10px;
        right: 10px;
        padding: 0.5em;
        font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
      }
      .sold {
        @extend .red-bg;
      }
      .reserved {
        @extend .yellow-bg;
      }
      .reference {
        @extend .blue-bg;
      }
  }
  .card-body {
    padding: 15px 0 0;
    text-align: justify;
    .card-title {
      font-size: 1.05em;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    }
    .description {
      display: block; /* Fallback for non-webkit */
      display: -webkit-box;
      height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
      margin: 0 auto;
      font-size: $font-size;
      line-height: $line-height;
      -webkit-line-clamp: $lines-to-show;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .price {
      margin-top: 15px;
      font-size: 1.5em;
    }
  }
}

