@import "colors";

.btn {
  transition: all 0.6s;
  &:focus { box-shadow: none!important; }
}

.btn-rounded {
  padding: 3px 7px;
  border-radius: 50%;
}

/*+
 * Color buttons
 */

.btn-color {
  @extend .white;
  border-color: transparent!important;

  &-blue {
    @extend .blue-bg;

    &:hover { @extend .dark-blue-bg; }
  }

  &-green {
    @extend .green-bg;

    &:hover { @extend .dark-green-bg; }
  }

  &-yellow {
    @extend .yellow-bg;

    &:hover { @extend .dark-yellow-bg; }
  }

  &-red {
    @extend .red-bg;

    &:hover { @extend .dark-red-bg; }
  }

  &-grey {
    @extend .grey-bg;

    &:hover { @extend .dark-grey-bg; }
  }

  &-black {
    @extend .black-bg;

    &:hover { @extend .black-bg; }
  }

  &-light {
    @extend .grey;
    @extend .light-bg;

    &:hover { @extend .black-bg; }
  }

  &-white {
    @extend .grey;
    @extend .white-bg;

    &:hover { @extend .white-bg; }
  }
}

/**
 * Bordered buttons
 */
.btn-bordered {
  @extend .white-bg;

  &-blue {
    @extend .blue;
    @extend .blue-border;

    &:hover, &:disabled {
      @extend .dark-blue;
      @extend .dark-blue-border;
    }
  }

  &-green {
    @extend .green;
    @extend .blue-border;

    &:hover, &:disabled {
      @extend .dark-blue;
      @extend .dark-blue-border;
    }
  }

  &-yellow {
    @extend .yellow;
    @extend .yellow-border;

    &:hover, &:disabled {
      @extend .dark-yellow;
      @extend .dark-yellow-border;
    }
  }

  &-red {
    @extend .red;
    @extend .red-border;

    &:hover, &:disabled {
      @extend .dark-red;
      @extend .dark-red-border;
    }
  }

  &-grey {
    @extend .grey;
    @extend .grey-border;

    &:hover, &:disabled {
      @extend .dark-grey;
      @extend .dark-grey-border;
    }
  }

  &-black {
    @extend .black;
    @extend .black-border;

    &:hover, &:disabled {
      @extend .black;
      @extend .black-border;
    }
  }
}

/**
 * Link buttons
 */
.btn-link {
  background-color: transparent!important;
  border-color: transparent!important;
  &:hover { text-decoration: underline!important; }

  &-blue {
    @extend .blue;
    &:hover { @extend .dark-blue; }
  }

  &-green {
    @extend .green;
    &:hover { @extend .dark-green; }
  }

  &-yellow {
    @extend .yellow;
    &:hover { @extend .dark-yellow; }
  }

  &-red {
    @extend .red;
    &:hover { @extend .dark-red; }
  }

  &-grey {
    @extend .grey;
    &:hover { @extend .dark-grey; }
  }

  &-black {
    @extend .black;
    &:hover { @extend .black; }
  }
}
