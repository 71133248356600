@import "../../../../style/index";

#main-info {
  .ref-row {
    display: flex;
    flex-direction: row;
    .sold {
      color: red !important;
    }
    .sale {
      @extend .green;
    }
    .is-sold {
      flex-grow: 1;
      text-align: right;
      font-weight: bold;
    }
    .reference {
      @extend .blue-bg;
      color: $white!important;
      padding: 5px 10px;
      border-radius: 2px;
      letter-spacing: 2px;
      font-weight: bold;
    }
  }

  .price-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-weight: bold;
  }
}