@import "../../../style/index";

#search-container {
  background-position: center;
  background-size: cover;
  width: 100%;
  min-height: 448px;
  @include for-tablet-portrait-down {
    min-height: 505px;
  }
  #form-container {
    float: left;
    top: 2.5em;
    padding: 30px;
    background-color: #fffffff2;
    position: relative;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      text-align: left;
      color: $grey;
      font-weight: bold;
      @include for-tablet-portrait-down {
       text-align: center;
      }
    }
    .form-group {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include for-tablet-portrait-down {
        justify-content: center;
      }
      label {
        width: 100px;
        text-align: left;
        padding-right: 5%;
        margin-bottom: 0;
        font-size: 15px;
      }
      h5 {
        padding: 0 15px;
        color: $grey;
        margin-bottom: 0;
      }
    }
    .btn-full-size {
      width: 100%;
    }
    .search-by-reference {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 1rem;
      @include for-tablet-portrait-down {
        justify-content: center;
      }
      .btn-ref {
        padding-left: 0;
        padding-right: 0;
        margin: 0;
      }
    }
  }
}