@import "../../../../style/index";

#description {
  p {
    text-align: justify!important;
    white-space: pre-line;
  }
  .ellipsis-text {
    p:first-child {
      @include for-tablet-landscape-down {
        display: block;
        display: -webkit-box;
        height: 100%;
        margin: 0 auto;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .btn {
      display: none;
      @include for-tablet-landscape-down { display: block; }
    }
  }
}